import {ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {borderRadius} from "@pg-design/helpers-css";

interface IProps {
    children: ReactNode;
    className?: string;
}

export const PrimaryBadge = (props: IProps) => (
    <span className={props.className} css={primaryBadge}>
        {props.children}
    </span>
);

const primaryBadge = (theme: Theme) => css`
    ${borderRadius(2)};
    background-color: ${theme.colors.primary};
    padding: 0.4rem 1.6rem;
    display: inline-block;
    text-align: start;
    font-weight: 700;
    font-size: 1.519rem;
    line-height: 2.4rem;
`;
