interface IGetOfferDistanceFromCenterPayload {
    stats: {
        distance_from_region: {
            center: boolean;
            region: string;
            distance: number;
        };
    } | null;
}

export const getOfferDistanceFromRegion = (offer: IGetOfferDistanceFromCenterPayload) => {
    if (!offer || !offer.stats || !offer.stats.distance_from_region) {
        return null;
    }

    const {
        distance_from_region: {distance, center, region}
    } = offer.stats;

    // If distance form region equals `0` then we set `0.1` - business requirement
    return `${distance || 0.1} km od ${center ? "centrum" : region}`;
};
