import {delayHit, hitAlgolytics} from "@pg-mono/algolytics";

import {ViewType} from "../../view_type/ViewType";
import {getTrackedOfferData, ITrackedOffer} from "./tracked_utils/tracked_offer";
import {getTrackedSiteData} from "./tracked_utils/tracked_site";
import {getTrackedVendorData, ITrackedVendor} from "./tracked_utils/tracked_vendor";

export const showPropertiesClickAlgolytics = delayHit((payload: Object) => hitAlgolytics("show_properties", payload), 500);

export const showPropertiesClick = (viewType: ViewType, offer: ITrackedOffer, vendor: ITrackedVendor) => {
    const payload = {
        view_type: viewType,
        ...getTrackedSiteData(),
        ...getTrackedVendorData(vendor),
        ...getTrackedOfferData(offer)
    };
    showPropertiesClickAlgolytics(payload);
};
