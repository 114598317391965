interface IGetOfferAddressTextPayload {
    street_name: string | null;
    street_number: string | null;
    region: {
        short_name_reverted: string | null;
    };
}

export function getOfferAddressText(offer: IGetOfferAddressTextPayload) {
    const streetName = offer.street_name ? `${offer.street_name}${offer.street_number ? ` ${offer.street_number}` : ""}` : "";
    const regionName = `${offer.street_name && offer.region.short_name_reverted ? "," : ""} ${offer.region.short_name_reverted ?? ""}`;

    return `${streetName}${regionName}`;
}
