import React from "react";
import {css, Theme} from "@emotion/react";

import {DesktopText} from "../../../atoms/typography/DesktopText";

const archivedBadge = (theme: Theme) => css`
    border-radius: 1.6rem;
    background-color: ${theme.colors.secondary};
    padding: 0.8rem 2.7rem;
    display: inline-block;
    color: white;
`;

export const ArchivedBadge = (props: {children: string; className?: string}) => (
    <span className={props.className} css={archivedBadge}>
        <DesktopText variant="info_txt_2">{props.children}</DesktopText>
    </span>
);
